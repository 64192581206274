@import 'partials/variables';

// General layout ---------------
body {
  background-color: #B5E7F7;
  padding-bottom: 2em;
  padding-top: 1em;

  &.not-front {
    [role='main'], [role='contentinfo'] {
      padding: 1%;

      @include breakpoint($tablet) {
        float: right;
        width: 78%;
      }
    }
  }
}

header,
.page {
  background-color: $white;
  padding: 0 1em;

  @include breakpoint($tablet) {
    margin-left: auto;
    margin-right: auto;
    width: 960px;
  }
}

.page {
  padding-top: 1em;

  @include breakpoint($tablet) {
    padding-bottom: 2em;
  }

  .sidebar-first {
    background-color: $blue;

    @include breakpoint($tablet) {
      float: left;
      width:	20%;
    }

    nav {
      margin: .5em .5em 1.5em;
    }
  }
}

.siteinfo {
  min-height: 160px;
  position: relative;
}

header {
  padding-bottom: 1em;

  @include breakpoint($tablet) {
    padding-bottom: 0;
  }

  hgroup {
    clear: both;
    @include breakpoint($tablet) {
      bottom: 10px;
      clear: none;
      position: absolute;
    }
  }

  nav {
    display: none;

    @include breakpoint($tablet) {
      display: block;
    }

    h2 {
      &.title {
        display: none;
      }
    }
  }

  figure {
    float: left;
    height: auto;
    margin-top: 4px;
    width: 35px;

    @include breakpoint($tablet) {
      bottom: 0;
      float: none;
      margin-top: 0;
      position: absolute;
      right: 30px;
      width: auto;
    }

    img {
      width: 100%;
    }
  }
}

.mobile-menu {
  float: right;
  @include breakpoint($tablet) {
    display: none;
  }

  .cross {
    display: none;
  }
}

.content {
  position: relative;
}

.front {
  @include breakpoint($tablet) {
    .field {
      &.field-type-text-with-summary {
        min-height: 400px;
        position: relative;
      }
    }

    article {
      float: left;
      width: 315px;
    }
  }

  [role='main']  {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    @include breakpoint($tablet) {
      article.node.node-page div.content div.field.field-type-text-with-summary p {
        bottom: 0;
        left: 0;
        padding-right: 5px;
        position: absolute;
        width: 315px;
      }
    }
  }
}

@include breakpoint($tablet) {
  .front_slider {
    float: left;
    height: 400px;
    margin-left: 6px;
    width: 634px;
  }
}

.front_blocks {
  color:  $blue;

  @include breakpoint($tablet) {
    margin-bottom: 20px;
  }

  h2 {
    border-bottom: 1px solid $blue;
    color:  $blue;
    font-size: 1.2em;
    margin-bottom: .5em;
    padding-bottom: .5em;
    text-transform: lowercase;
  }

  .front_block,
  .contextual-links-region {
    background-color: $gray;
    padding: 1em;
    margin-bottom: 1em;

    @include breakpoint($tablet) {
      float: left;
      margin-right: 1%;
      margin-top: 1em;
      min-height: 150px;
      padding: 1%;
      width: 30.6%;
      // height kan misschein 100% worden
    }
  }

  @include breakpoint($tablet) {
    .front_block {
      &:last-child {
        margin-right: 0;
      }

      .last-block {
        margin-right: 0;
      }
    }
  }
}


[role='main'] {
  color: $black;
  margin-bottom: 1em;
  min-height: 300px;

  .view-id-persons {
    @include breakpoint($tablet) {
      #view-persons-image {
        float: left;
      }
    }

    #view-persons-description {
      margin-top: 1em;

      @include breakpoint($tablet) {
        float: left;
        margin-left: .5em;
        margin-top: 0;
        position: relative;
        width: 70%;
      }
    }

    .views-field-title {
      font-family: 'Open Sans', sans-serif;
      font-weight: 500;
      margin-bottom: .6em;
    }

    .views-field-field-job-title {
      font-family: 'Open Sans', sans-serif;
      font-style: italic;
      font-weight: 300;
      margin-bottom: 1em;
    }

    .views-row {
      border-bottom: 1px solid $blue;
      margin-bottom: 1em;
      padding-bottom: .5em;

      &.last {
        border-bottom: none;
      }
    }
  }
}

.views-field-changed {
  font-family: 'Open Sans', sans-serif;
  font-size: .8em;
  margin-bottom: 1.2em;
}

.views-field.views-field-view-node {
  font-family: 'Open Sans', sans-serif;
  font-size: .9em;
  margin-bottom: 1em;
}

// Nieuws view ---------------
.view-id-nieuws {
  .view-display-id-page {
    .views-row {
      margin-bottom: 3em;
    }
  }
}
